import './navigation-teaser.scss';

import Swiper, { Pagination } from 'swiper';

class NavigationTeaser {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-navigationteaser',
            sliderAttr: 'slider',
            slideAttr: 'slide'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$navigationTeaser = $element;
        this.$navigationTeaserContainer = this.$navigationTeaser.querySelector(`[${this.settings.initAttr}="${this.settings.sliderAttr}"]`);
        this.$navigationTeaserSlides = this.$navigationTeaser.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideAttr}"]`);
        this.navigationTeaserSlider = null;

        if (this.$navigationTeaserContainer && this.$navigationTeaserSlides.length > 1) {
            this.initialize();
        }
    }

    initialize () {
        this.checkSize();
        this.setEvents();
    }

    checkSize () {
        if (window.mediaMatchHandler[1440].matches) {
            if (this.navigationTeaserSlider !== null) {
                this.destroySlider();
            }
        } else if (window.mediaMatchHandler[1024].matches && this.$navigationTeaserSlides.length > 1) {
            if (this.navigationTeaserSlider === null) {
                this.initSlider();
            }
        } else if (window.mediaMatchHandler[768].matches) {
            if (this.navigationTeaserSlider !== null) {
                this.destroySlider();
            }
        } else {
            if (this.$navigationTeaserSlides.length > 1) {
                if (this.navigationTeaserSlider === null) {
                    this.initSlider();
                }
            }
        }
    }

    setEvents () {
        [1440, 1024, 768, 320].forEach((bp) => {
            window.mediaMatchHandler[bp].addEventListener('change', () => {
                this.checkSize();
            });
        });
    }

    initSlider () {
        this.navigationTeaserSlider = new Swiper(this.$navigationTeaserContainer, {
            effect: 'slide',
            modules: [Pagination],
            cardsEffect: {
                slideShadows: false
            },
            speed: 500,
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            slidesPerGroupAuto: true,
            centeredSlides: true,
            centeredSlidesBounds: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        });
    }

    destroySlider () {
        if (this.navigationTeaserSlider !== null) {
            this.navigationTeaserSlider.destroy(true, true);
            this.navigationTeaserSlider = null;
        }
    }
}

export { NavigationTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$navigationTeaser = $context.querySelectorAll('[data-navigationteaser="root"]');
        for (let i = 0; i < $$navigationTeaser.length; i++) {
            const $navigationTeaser = $$navigationTeaser[i];

            const navigationTeaserAPI = new NavigationTeaser($navigationTeaser);
            $navigationTeaser.API = navigationTeaserAPI;
        }
    }
});
